import React from "react"
import MainLayout from "../components/MainLayout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function NotFound({ data }) {
  return (
    <MainLayout>
      <div className="page-header">
        <div className="page-header-titles">
          <h2>Gracias</h2>
        </div>
        <GatsbyImage
          image={data.titleImage.childImageSharp.gatsbyImageData}
          className="page-header-background"
          alt="thumb"
          objectFit="cover"
          objectPosition="25% 50%"
        />
      </div>
      <div className="layout">
        <div className={`page-content`}>
          <h2 style={{textAlign: 'center'}}>¡Te has suscrito al Newsletter!</h2>
          <h3 style={{textAlign: 'center'}}>Pronto recibirás un mensaje a tu email</h3>
        </div>
      </div>
    </MainLayout>
  )
}
export const query = graphql`
  query ThanksNewsletterPage {
    titleImage: file(relativePath: { eq: "featured/title-1.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
